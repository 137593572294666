<template>
  <fragment>
    <div class="alert alert-danger" v-if="isError">
      Oops! An error occurred while attempting to log you in.
    </div>

    <div class="alert alert-danger" v-if="!isLoginValid">
      You have entered the wrong username or password, please try again.
    </div>

    <form class="d-flex flex-column" v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" name="email" type="text" class="form-control" :class="{ 'is-invalid': errors.has('email') }"
          autocomplete="off" placeholder="Please enter username" v-validate="'required'" v-model="login.username" data-vv-as="Email" />
        <span class="invalid-feedback" aria-live="polite">Email field is required.</span>
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.has('password') }"
          autocomplete="off" placeholder="Please enter your password" v-validate="'required'" v-model="login.password" data-vv-as="Password" />
        <span class="invalid-feedback" aria-live="polite">Password field is required.</span>
      </div>

      <button type="submit" class="btn btn-success ld-ext-right" :class="{ 'running' : isProcessing }" :disabled="isProcessing">
        Login
        <div class="ld ld-ring ld-spin"></div>
      </button>

      <div class="d-flex justify-content-center">
        <router-link to="/forgotpassword" class="font-weight-medium text-center d-block">Forgot Password?</router-link>
        <span class="mr-2 ml-2" v-if="publicSignup.show">|</span>
        <router-link :to="publicSignup.url" class="font-weight-medium text-center d-block" v-if="publicSignup.show">{{ publicSignup.text }}</router-link>
      </div>

    </form>
  </fragment>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data: function() {
    return {
      isProcessing: false,
      isLoginValid: true,
      isError: false,
      login: {
        username: null,
        password: null
      },
      base64Image: null,
      version: process.env.VERSION,
      publicSignup: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_PUBLIC_SIGNUP_SHOW || 'true').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_PUBLIC_SIGNUP_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_PUBLIC_SIGNUP_TEXT
      },
    };
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      this.authorizeUser();
    },
    authorizeUser() {
      this.isProcessing = true;
      this.authorize(this.login)
        .then(() => {
          let redirect = this.$route.query.redirect;
          this.$router.push(redirect || "dashboard");
        })
        .catch(err => {
          this.isProcessing = false;

          if (err.response && err.response.data.model && err.response.data.model.errors) {
            return this.isLoginValid = false;
          }
          else {
            this.isError = true;
          }
        });
    },
    ...mapActions(["authorize"])
  },
  async mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.login.username = "sadmin@esolutionsgroup.ca";
      this.login.password = "$Link2Build2019";
    }
  }
};
</script>
